import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import ScrollTop from "../components/aside/ScrollTop";
import Login from "../components/auth/Login";
import ExampleList from "../components/Example/ExampleList";
import PrivateRoute from "./PrivateRoute";
import PaymentRoute from "../components/payment/PaymentRoute";
import DashboardRoute from "../components/dashboard/DashboardRoute";
import MonitoringRoute from "../components/monitoring/MonitoringRoute";
import TerminalRoute from "../components/terminals/TerminalRoute";
import PlaceRoute from "../components/place/PlaceRoute";
import IncassatorRoute from "../components/incass/IncassatorRoute";
import SIMRoute from "../components/simcard/SIMRoute";
import CommissionRoute from "../components/commission/CommissionRoute";
import ListRoute from "../components/list/ListRoute";
import SettingsRoute from "../components/settings/SettingsRoute";
import CommandsRoute from "../components/Commands/CommandsRoute";
import UserRoute from "../components/user/UserRoute";
import DictionariesRoute from "../components/Dictionaries/DictionariesRoute";
import ReportRoute from "../components/Report/ReportRoute";

export default function AppRouter() {


    return (
        <>
                <Router>
                    <Route path="/login" component={Login}/>
                    <div className="kt-grid kt-grid--hor kt-grid--root">
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                            <div
                                className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                                id="kt_wrapper">
                                <PrivateRoute exact path="/" component={DashboardRoute}/>
                                <PrivateRoute path="/payment" component={PaymentRoute}/>
                                <PrivateRoute path="/monitoring" component={MonitoringRoute}/>
                                <PrivateRoute path="/terminal" component={TerminalRoute}/>
                                <PrivateRoute path="/incass" component={IncassatorRoute}/>
                                <PrivateRoute path="/place" component={PlaceRoute}/>
                                <PrivateRoute path="/commissions" component={CommissionRoute}/>
                                <PrivateRoute path="/report" component={ReportRoute}/>
                                <PrivateRoute path="/SIMCard" component={SIMRoute}/>
                                <PrivateRoute path="/list" component={ListRoute}/>
                                <PrivateRoute path="/settings" component={SettingsRoute}/>
                                <PrivateRoute path="/dictionaries" component={DictionariesRoute}/>
                                <PrivateRoute path="/commands" component={CommandsRoute}/>
                                <PrivateRoute path="/user" component={UserRoute}/>
                                <PrivateRoute path="/Example/" component={ExampleList}/>
                            </div>
                        </div>
                    </div>
                    <Route path="/example" component={ExampleList}/>
                </Router>
            <ScrollTop/>
        </>
    );
}

